import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import useSearch from '../../hooks/useSearch';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from '../../hooks/useTranslation';
import { useHistory, useParams } from 'react-router-dom';
import { useProductConfig } from 'src/hooks/useFetchData';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const SearchIconWrapper = styled.button`
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 100%;
  padding: 0 13px;

  &:focus,
  &:active {
    outline: none;
  }
`;

const SearchIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  animation: ${show} 0.8s;
  width: ${({ width }) => (width ? width : '280px')};
  align-self: center;
`;

const SearchInput = styled.input<{
  hasValue: boolean;
  isSearchPage: boolean;
  inSearchSidebar?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.light};
  padding: 14px 16px;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.elvitaGray};
  letter-spacing: 0.05em;
  outline: ${({ theme, inSearchSidebar }) =>
    inSearchSidebar
      ? `1px solid ${theme.colors.elvitaGray}`
      : `1px solid ${theme.colors.elvitaLighterGray}`};
  border-radius: 22px;

  &:hover {
    outline-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.elvitaGray};
  }
`;

const ClearIconWrapper = styled.button`
  display: flex;
  animation: ${show} 0.8s;
  position: absolute;
  right: 50px;
  cursor: pointer;
  height: 100%;
  align-items: center;
`;

const ClearIcon = styled.img`
  width: 13.5px;
  height: 13.5px;
`;

type SearchBoxProps = {
  isSearchPage?: boolean;
  searchType: Elvita.SearchType;
  width?: string;
  setSearchResults?(
    data: Elvita.SearchResultDocument[],
    searchTerm: string,
    loading: boolean,
  ): void;
  forceGlobalSearch?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
  preventEnterNavigation?: boolean;
  inSearchSidebar?: boolean;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  isSearchPage,
  searchType,
  width,
  setSearchResults,
  forceGlobalSearch = false,
  inputRef,
  autoFocus = false,
  preventEnterNavigation = false,
  inSearchSidebar = false,
}) => {
  const { trackSiteSearch } = useMatomo();
  const history = useHistory();
  const { searchTerm } = useParams<{ searchTerm: string }>();
  const { data: productConfig } = useProductConfig();
  const { t } = useTranslation();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [value, setValue] = React.useState<string>(searchTerm ?? '');
  const debouncedValue = useDebounce<string>(value, 300);
  const { isLoading, isError, data } = useSearch(
    debouncedValue,
    searchType,
    forceGlobalSearch,
  );
  const [renderDetailedResults, setRenderDetailedResults] = React.useState(
    debouncedValue && isSearchPage ? true : false,
  );

  const getSearchLink = (searchValue: string) => {
    if (!searchValue) return '';

    if (productConfig?.ProductId) {
      return `/${productConfig.ProductId}/search/${searchType}/${searchValue}`;
    }

    return `/search/${searchType}/${searchValue}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenderDetailedResults(true);
    setValue(e.target.value);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (preventEnterNavigation) {
        e.preventDefault();
        return;
      }
      const searchPath = getSearchLink(value);
      if (searchPath) {
        history.push(searchPath);
      }
    }
  };

  const handleSearch = () => {
    if (inSearchSidebar) {
      setSearchResults?.(data ? data : [], value, isLoading);
    } else {
      const searchPath = getSearchLink(value);
      if (searchPath) {
        history.push(searchPath);
      }
    }
  };

  React.useEffect(() => {
    if (!isSearchPage || (isSearchPage && renderDetailedResults)) {
      setSearchResults?.(data ? data : [], debouncedValue, isLoading);
    }
  }, [
    isSearchPage,
    data,
    renderDetailedResults,
    debouncedValue,
    isLoading,
    setSearchResults,
  ]);

  return (
    <Wrapper ref={wrapperRef} width={width}>
      <SearchInput
        isSearchPage={isSearchPage}
        type="text"
        placeholder={
          searchType === 'product'
            ? t('product.searchbox.watermark')
            : t('content.searchbox.watermark')
        }
        value={value}
        hasValue={debouncedValue.length > 0}
        onChange={handleChange}
        onKeyUp={handleKeypress}
        ref={inputRef}
        autoFocus={autoFocus}
        inSearchSidebar={inSearchSidebar}
      />
      {debouncedValue.length > 0 && (
        <ClearIconWrapper
          onMouseDown={() => {
            setValue('');
            if (isSearchPage) {
              history.push(getSearchLink(`${searchType}`));
            }
          }}
        >
          <ClearIcon src="/images/clear-icon.svg" />
        </ClearIconWrapper>
      )}
      <SearchIconWrapper onClick={handleSearch}>
        <SearchIcon src="/images/search-icon.svg" />
      </SearchIconWrapper>
    </Wrapper>
  );
};

export default SearchBox;
