import * as React from 'react';
import { useFetchBrowse } from '../hooks/useFetchData';
import styled from 'styled-components';
import { useLinkFromId } from 'src/helpers/link';
import { Link, useParams } from 'react-router-dom';

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space.xxl};
  flex: 1;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    flex: 0;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space.xxxxl};
  }
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 19.2px;
  color: ${({ theme }) => theme.colors.elvitaGray};
  font-weight: 300;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.space.m};
`;

type SidebarProps = {
  items: Elvita.Node[];
  title: string;
};

const Sidebar: React.FC<SidebarProps> = ({ items, title }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {items.map((item, index) => (
        <Item item={item} key={index} />
      ))}
    </Wrapper>
  );
};

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.elvitaBlack : theme.colors.elvitaWebPurple};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.space.m};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const CaretIcon = styled.img`
  width: 6px;
  height: 14px;
  transform: rotate(180deg);
  margin-left: ${({ theme }) => theme.space.md};
`;

const Item: React.FC<{ item: Elvita.Node }> = ({ item }) => {
  const { data, isError } = useFetchBrowse();
  const { topicId } = useParams<{ topicId: string }>();

  const BrowseLink = useLinkFromId(data?.data, item.topicId);

  if (isError || !data || data.status === 404) {
    return null;
  }

  return (
    <StyledLink to={BrowseLink} $isActive={item.topicId === topicId}>
      {item.title} {item?.children && <CaretIcon src="/images/caret.svg" />}
    </StyledLink>
  );
};

export default Sidebar;
