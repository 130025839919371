import * as React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFetchMenu } from 'src/hooks/useFetchData';
import { useTranslation } from 'src/hooks/useTranslation';
import styled from 'styled-components';
import MenuLink from './MenuLink';
import Portal from './Portal';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  height: 100%;
  align-self: center;
  justify-content: flex-end;
  top: 0;
  z-index: 999;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.elvitaWebBeige};
  width: 270px;
  padding: 6px;
`;

const BackIcon = styled.img`
  width: 44px;
  align-self: flex-end;
  cursor: pointer;
`;

const Menu = styled.ul`
  padding: ${({ theme }) => `${theme.space.md} ${theme.space.l} `};
`;

const MenuItem = styled.li`
  padding: ${({ theme }) => `${theme.space.xxs} 0`};

  &:first-child {
    padding-top: 0;
  }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
  padding-left: ${({ theme }) => theme.space.xs};
`;

const StartLink = styled(Link)<{ $active?: boolean }>`
  font-family: ${({ theme, $active }) =>
    $active ? theme.fonts.bold : theme.fonts.light};
`;

type DrawerMenuProps = {
  isVisible: boolean;
  closeHandler(value: boolean): void;
};

const DrawerMenu: React.FC<DrawerMenuProps> = ({ isVisible, closeHandler }) => {
  const { data: menu } = useFetchMenu();
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { pathname } = useLocation();

  if (!isVisible || !menu) {
    return null;
  }

  return (
    <Portal id="#drawer">
      <Wrapper>
        <InnerWrapper>
          <BackIcon
            src="/images/back.svg"
            onClick={() => closeHandler(false)}
          />
          <Menu>
            <MenuItem>
              <StartLink
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                to={`/${productId}`}
                $active={pathname === `/${productId}`}
              >
                <img src={`/images/elvita-symbol.svg`} />
                <Label>{t('menu.productStartLabel')}</Label>
              </StartLink>
            </MenuItem>
            {menu?.map((item, index) => (
              <DrawerMenuItam key={index} item={item} />
            ))}
            <MenuItem>
              <Link
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                to="/"
              >
                <img src={`/images/elvita-symbol.svg`} />
                <Label>{t('menu.productWIzardLabel')}</Label>
              </Link>
            </MenuItem>
          </Menu>
        </InnerWrapper>
      </Wrapper>
    </Portal>
  );
};

type DrawerMenuItamProps = {
  item: Elvita.MenuItem;
};

const DrawerMenuItam: React.FC<DrawerMenuItamProps> = ({ item }) => {
  return (
    <MenuItem>
      <MenuLink item={item}>
        <img src={`/images/elvita-symbol.svg`} />
        <Label>{item.title}</Label>
      </MenuLink>
    </MenuItem>
  );
};

export default DrawerMenu;
