import * as React from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { useInternalLink } from 'src/hooks/useInternalLink';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import { useLinkFromId } from 'src/helpers/link';
import { Link } from 'react-router-dom';
import consts from 'src/config/consts';

const pointSize = 40;

const HotspotPoint = styled.img<{ left: string; top: string }>`
  position: absolute;
  width: ${pointSize}px;
  height: ${pointSize}px;
  left: ${({ left }) => `${parseFloat(left) * 100}%`};
  top: ${({ top }) => `${parseFloat(top) * 100}%`};
  transform: translate(-${pointSize / 2}px, -${pointSize / 2}px);
  cursor: pointer;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 9px 12px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  line-height: 12px;
  letter-spacing: 0.05em;
  border-radius: 6px;

  &:after {
    background: url(/images/arrow.svg);
    background-size: contain;
    content: ' ';
    width: 12px;
    height: 7px;
    background-repeat: no-repeat;
    margin-left: ${({ theme }) => theme.space.s};
    align-self: center;
    background-position: right;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.elvitaWebPink};

    &:after {
      background: url(/images/arrow-active.svg);
      background-size: contain;
      content: ' ';
      background-repeat: no-repeat;
      background-position: right;
    }
  }
`;

const PopoverLink = styled(Link)`
  display: block;
  cursor: pointer;
  padding-bottom: 40px;
  margin-bottom: -40px;
  padding-left: 40px;
  margin-left: -40px;
`;

type HotspotPopoverProps = {
  point: Elvita.Point;
  parentElement: HTMLElement;
};

const HotspotPopover: React.FC<HotspotPopoverProps> = ({
  point,
  parentElement,
}) => {
  const [opened, setOpened] = React.useState(false);
  const { internalLink } = useInternalLink();
  const { data } = useFetchBrowse();
  const idLink = useLinkFromId(data?.data, point.link);

  const getLink = () => {
    const link =
      point.type === 'hotspot'
        ? internalLink(`/hotspots/${point.link}`)
        : idLink;

    return link;
  };

  return (
    <Popover
      align="start"
      isOpen={opened}
      content={
        <PopoverLink
          className="hotspot-link"
          to={getLink()}
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
        >
          <PopoverContent>{point.text}</PopoverContent>
        </PopoverLink>
      }
      parentElement={parentElement}
      onClickOutside={() => setOpened(false)}
      containerStyle={{
        top: '-5px',
        left: '15px',
        borderRadius: '2px',
        maxWidth: '120px',
      }}
    >
      <HotspotPoint
        src={
          opened
            ? '/images/hotspot-point-active.svg'
            : '/images/hotspot-point.svg'
        }
        left={point.x}
        top={point.y}
        onClick={() => setOpened(true)}
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
      />
    </Popover>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  display: inline-block;

  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
`;

type HotspotImageProps = {
  image: Elvita.HotspotImage;
};

const HotspotImage: React.FC<HotspotImageProps> = ({ image }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (wrapperRef?.current) {
      setLoaded(true);
    }
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Image src={`${consts.imagesCdnUrl}/images/${image.imageId}`} />
      {loaded &&
        image.points.map((point, index) => (
          <HotspotPopover
            parentElement={wrapperRef.current}
            point={point}
            key={index}
          />
        ))}
    </Wrapper>
  );
};

export default HotspotImage;
