import * as React from 'react';
import styled from 'styled-components';
import PageContentWrapper from './layout/PageContentWrapper';
import DrawerMenu from './DrawerMenu';
import { useFetchMenu, useFetchStartPage } from '../hooks/useFetchData';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import MenuLink from './MenuLink';
import { useTranslation } from '../hooks/useTranslation';
import H2 from './typography/H2';
import H1 from './typography/H1';
import SearchSidebar from './search/SearchSidebar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => `${theme.space.m} 14px`};
  padding-left: 20px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    padding: ${({ theme }) => `21px ${theme.space.md} 21px ${theme.space.md}`};
  }
`;

const Logo = styled.img`
  width: 76px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    width: 110px;
  }
`;

const Icon = styled.img<{ type?: 'hamburger' }>`
  cursor: pointer;
  z-index: 9;

  &:nth-child(1) {
    margin-left: ${({ theme }) => theme.space.m};
    @media (min-width: ${({ theme }) => theme.screens.sm}) {
      margin-left: ${({ theme }) => theme.space.l};
    }
  }

  &:nth-child(3) {
    margin-left: ${({ theme }) => theme.space.lg};
    @media (min-width: ${({ theme }) => theme.screens.sm}) {
      margin-left: ${({ theme }) => theme.space.l};
    }
  }
  @media (min-width: ${({ theme }) => theme.screens.l}) {
    display: ${({ type }) => type === 'hamburger' && 'none'};
  }

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    margin-left: ${({ theme }) => theme.space.m};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled.a`
  width: 76px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    width: 110px;
  }
`;
const LinkWrapper = styled.div`
  width: 76px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    width: 110px;
  }
`;

const LogoWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: 180px;
  }
`;

const InfoWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  max-width: 130px;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      h1,
      h2 {
        color: ${({ theme }) => theme.colors.elvitaWebPink};
      }
    }
  }
`;

const StyledH1 = styled(H1)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.m};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: right;
  color: ${({ theme }) => theme.colors.elvitaGray};
`;

const StyledH2 = styled(H2)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.m};
  font-family: ${({ theme }) => theme.fonts.light};
  text-align: right;
  color: ${({ theme }) => theme.colors.elvitaGray};
`;

const SearchButton = styled.div<{ isHidden: boolean }>`
  font-family: ${({ theme }) => theme.fonts.light};
  padding: 14px 16px;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.elvitaGray};
  letter-spacing: 0.05em;
  outline: 1px solid ${({ theme }) => theme.colors.elvitaGray};
  border-radius: 22px;
  background: ${({ theme }) => theme.colors.elvitaWhite};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.xxxxl};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    outline-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  }

  img {
    width: 14px;
    height: 14px;
  }

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    outline: none;

    img {
      height: 24px;
      width: 24px;
    }

    span {
      display: none;
    }
  }
`;

const SearchIcon = styled.img`
  margin-left: ${({ theme }) => theme.space.l};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: 24px;
    height: 24px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(SearchIcon)<{ isHidden: boolean }>`
  margin-left: ${({ theme }) => theme.space.s};
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  transition: opacity 0.2s ease-in-out;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    margin-left: 0;
  }
`;

type HeaderProps = {
  hideSearch: boolean;
  hideMenu: boolean;
  isWizard: boolean;
};

const Header: React.FC<HeaderProps> = ({ hideSearch, hideMenu, isWizard }) => {
  const { error, data } = useFetchStartPage();
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const { t } = useTranslation();

  const [showSearchInfo, setShowSearchInfo] = React.useState(false);

  const handleInfoClick = () => {
    setShowSearch(true);
    setShowSearchInfo(true);
  };

  const handleSearchClose = (value: boolean) => {
    setShowSearch(value);
    setShowSearchInfo(false);
  };

  const toggleDrawerMenu = React.useCallback(
    (value: boolean) => setShowDrawer(value),
    [],
  );

  const toggleSearch = React.useCallback(
    (value: boolean) => setShowSearch(value),
    [],
  );

  const handleProductSearch = () => {
    if (productId) {
      history.push(`/${productId}/search/content`);
    }
  };

  const getHomeUrl = () => {
    return '/';
  };

  return (
    <>
      <PageContentWrapper type="header" noPadding>
        <Wrapper>
          <LogoWrapper>
            <LinkWrapper>
              <LogoLink href={getHomeUrl()}>
                <Logo src="/images/logo.svg" />
              </LogoLink>
            </LinkWrapper>
          </LogoWrapper>
          <Center>{!hideMenu && <Menu isWizard={isWizard} />}</Center>
          <Right>
            {data?.status === 200 && (
              <InfoContainer>
                <InfoWrapper to="/">
                  <StyledH1>{data?.data.title}</StyledH1>
                  <StyledH2>{data?.data.description}</StyledH2>
                </InfoWrapper>
                {productId && (
                  <SearchIcon
                    src="/images/search-icon.svg"
                    alt="Search icon"
                    onClick={handleProductSearch}
                  />
                )}
              </InfoContainer>
            )}
            {(!hideSearch || isWizard) && !productId && (
              <>
                <SearchButton
                  isHidden={showSearch}
                  onClick={() => toggleSearch(true)}
                >
                  <span>{t('product.searchbox.watermark')}</span>
                  <SearchIcon src="/images/search-icon.svg" alt="Search icon" />
                </SearchButton>
                <InfoIcon
                  src="/images/info-white.svg"
                  alt="Info icon"
                  onClick={handleInfoClick}
                  isHidden={showSearch}
                />
              </>
            )}
            {!hideMenu && (
              <Icon
                type="hamburger"
                src="/images/hamburger.svg"
                alt="Menu trigger"
                onClick={() => {
                  toggleDrawerMenu(true);
                }}
              />
            )}
          </Right>
        </Wrapper>
      </PageContentWrapper>
      {!hideMenu && (
        <DrawerMenu isVisible={showDrawer} closeHandler={toggleDrawerMenu} />
      )}
      {(!hideSearch || isWizard) && !productId && (
        <SearchSidebar
          isVisible={showSearch}
          closeHandler={handleSearchClose}
          searchType="product"
          openWithInfo={showSearchInfo}
        />
      )}
    </>
  );
};

const MenuWrapper = styled.ul`
  display: none;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    display: flex;
    padding-left: 0;
  }
`;

const Item = styled.li`
  margin: 0 22px;
  display: flex;
  max-width: 160px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const A = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  letter-spacing: 0.05em;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }
`;

const StartLink = styled(Link)<{ $active?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  font-family: ${({ theme, $active }) =>
    $active ? theme.fonts.bold : theme.fonts.light};
`;

type MenuProps = {
  isWizard?: boolean;
};

const Menu: React.FC<MenuProps> = ({ isWizard }) => {
  const { error, data } = useFetchMenu();
  const { productId } = useParams<{ productId: string }>();
  const { pathname } = useLocation();

  const { t } = useTranslation();
  if (!data || error) {
    return null;
  }

  return (
    <MenuWrapper>
      <Item>
        <StartLink
          to={isWizard ? `/` : `/${productId}`}
          $active={pathname === `/${productId}`}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('menu.productStartLabel')}
        </StartLink>
      </Item>
      {data.map((item, index) => (
        <Item key={index}>
          <MenuLink item={item}>
            <A>{item.title}</A>
          </MenuLink>
        </Item>
      ))}
    </MenuWrapper>
  );
};

export default Header;
