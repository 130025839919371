import * as React from 'react';
import styled from 'styled-components';
import SearchBox from './SearchBox';
import Portal from '../Portal';
import ProductList from '../ProductList';
import Loader from '../Loader';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import H2 from '../typography/H2';
import { useTranslation } from 'src/hooks/useTranslation';
import { useProductCategory } from 'src/hooks/useProductCategory';

const Overlay = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
`;

const SidebarWrapper = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 0;
  width: calc(${({ theme }) => theme.sizes.containerWide} * 0.5);
  height: 100%;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.elvitaWhite};
  z-index: 999;
  padding: ${({ theme }) => theme.space.s};
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.4);
  transform: translateX(${({ $isVisible }) => ($isVisible ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};

  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    right: 0;
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.xs};
`;

const CloseButton = styled.button`
  cursor: pointer;
  padding: ${({ theme }) => theme.space.xxxs};
  margin-right: ${({ theme }) => theme.space.s};
  width: ${({ theme }) => theme.space.m};
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.l};
`;

const ResultsWrapper = styled.div`
  margin: ${({ theme }) => theme.space.m} 0;
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: end;
  margin: 0 ${({ theme }) => theme.space.xs};
`;

const SearchInfoIcon = styled.div<{ $isActive?: boolean }>`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.space.m};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
  }
`;

const InfoContent = styled.div`
  padding-top: ${({ theme }) => theme.space.sm};
  margin: 0 ${({ theme }) => theme.space.xs};
`;

const StyledH2 = styled(H2)`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.space.s};
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;

const InfoText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  margin-bottom: ${({ theme }) => theme.space.xl};
  text-align: left;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const ResultsCount = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: 12px;
  color: ${({ theme }) => theme.colors.elvitaGray};
  margin: ${({ theme }) => theme.space.m} 0;
  padding-left: ${({ theme }) => theme.space.xs};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding-left: 0;
  }
`;

type SearchSidebarProps = {
  isVisible: boolean;
  closeHandler: (value: boolean) => void;
  searchType: Elvita.SearchType;
  openWithInfo?: boolean;
};

const SearchSidebar: React.FC<SearchSidebarProps> = ({
  isVisible,
  closeHandler,
  searchType,
  openWithInfo = false,
}) => {
  const { trackSiteSearch } = useMatomo();
  const { t } = useTranslation();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = React.useState<
    Elvita.SearchResultDocument[] | Elvita.ProductSearchDocument[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [showSearchInfo, setShowSearchInfo] = React.useState(false);
  const [value, setValue] = React.useState('');
  const { findCategory } = useProductCategory();

  React.useEffect(() => {
    if (isVisible && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (!isVisible) {
      setShowSearchInfo(false);
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (isVisible && openWithInfo) {
      setShowSearchInfo(true);
    }
  }, [isVisible, openWithInfo]);

  const handleSearchResults = React.useCallback(
    (
      data: Elvita.SearchResultDocument[],
      searchTerm: string,
      loading: boolean,
    ) => {
      setLoading(loading);
      setSearchResults(data);
      setValue(searchTerm);

      if (searchTerm && searchTerm !== '' && !loading) {
        trackSiteSearch({ keyword: searchTerm });
      }
    },
    [trackSiteSearch],
  );

  const categorySearchResults = React.useMemo(() => {
    if (!searchResults.length) return searchResults;

    return searchResults.map(result => {
      const category = findCategory(result.manifestId);
      return {
        ...result,
        category,
        productId: result.objectId.split('_')[0],
        title: result.description,
        validity: parseInt(result.manifestId.split('_')[0], 10),
      };
    });
  }, [searchResults, findCategory]);

  return (
    <Portal id="#drawer">
      {isVisible && (
        <>
          <Overlay $isVisible={isVisible} onClick={() => closeHandler(false)} />
          <SidebarWrapper $isVisible={isVisible}>
            <SidebarHeader>
              <CloseButton onClick={() => closeHandler(false)}>
                <img src="/images/close.svg" alt="Close" />
              </CloseButton>
              <SearchBoxWrapper>
                <SearchBox
                  isSearchPage={false}
                  searchType={searchType}
                  width="100%"
                  setSearchResults={handleSearchResults}
                  forceGlobalSearch={true}
                  inputRef={searchInputRef}
                  autoFocus={true}
                  preventEnterNavigation={true}
                  inSearchSidebar={true}
                />
                <SearchInfoIcon
                  $isActive={showSearchInfo}
                  onClick={() => setShowSearchInfo(!showSearchInfo)}
                >
                  <img
                    src={
                      showSearchInfo
                        ? '/images/info-purple.svg'
                        : '/images/info-white.svg'
                    }
                    alt="Info icon"
                  />
                </SearchInfoIcon>
              </SearchBoxWrapper>
            </SidebarHeader>
            {showSearchInfo && (
              <InfoContent>
                <StyledH2>{t(`${searchType}.search.title`)}</StyledH2>
                <InfoText>{t(`${searchType}.search.preamble`)}</InfoText>
                <StyledImage
                  src={`${
                    searchType === 'product'
                      ? '/images/search-product.png'
                      : '/images/search-content.jpg'
                  }`}
                  alt="Search info"
                />
              </InfoContent>
            )}
            {searchResults.length > 0 && !loading && (
              <ResultsCount>
                {t(`${searchType}.search.resultsCount`)
                  .replace('_TOTAL_', searchResults.length)
                  .replace('_TERM_', value)}
              </ResultsCount>
            )}
            <ResultsWrapper>
              {loading ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                searchResults.length > 0 && (
                  <ProductList
                    items={categorySearchResults}
                    inSearchSidebar={true}
                  />
                )
              )}
            </ResultsWrapper>
          </SidebarWrapper>
        </>
      )}
    </Portal>
  );
};

export default SearchSidebar;
