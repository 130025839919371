import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLanguageStore } from 'src/context/LanguageStore';
import consts from 'src/config/consts';
import { isProduct, isWizard } from '../../helpers/wizard';
import path from 'path';

const ProductListWrapper = styled(Link)<{
  $noHref: boolean;
  inSearchSidebar?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ inSearchSidebar }) => (inSearchSidebar ? '100%' : '450px')};
  margin: -6px 0;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.elvitaLighterGray};
  }

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: 100%;
  }
`;

const ProductListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-bottom: 1px solid ${({ theme }) => theme.colors.elvitaLighterGray};
  padding: ${({ theme }) => theme.space.sm} ${({ theme }) => theme.space.sm};

  &:hover {
    background-color: ${({ theme }) => theme.colors.elvitaLighterGray};
  }
`;

const Product = styled.div<{ $noHref: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: ${({ $noHref }) => ($noHref ? 'default' : 'pointer')};
  pointer-events: ${({ $noHref }) => $noHref && 'none'};
  flex: 1;
  padding: ${({ theme }) => theme.space.m} ${({ theme }) => theme.space.sm};
`;

const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: ${({ theme }) => theme.space.sm};
  object-fit: cover;
`;

const ProductTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  margin: 0;
`;

const ChevronIcon = styled.img`
  width: 9px;
  height: 17px;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.space.sm};
`;

const ProductContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

type ProductItemProps = {
  item: Elvita.Product;
  inSearchSidebar?: boolean;
};

const ProductItem: React.FC<ProductItemProps> = ({ item, inSearchSidebar }) => {
  const lang = useLanguageStore(state => state.lang);
  const [itemObject, setItemObject] = React.useState<
    { id: string; title?: string; imageId: string } | undefined
  >(undefined);

  React.useEffect(() => {
    if (isProduct(item)) {
      setItemObject({
        id: item.validity.toString(),
        title: item.title,
        imageId: item.imageId,
      });
    }
  }, [item]);

  const buildHref = (): string => {
    let url: string;
    if (isProduct(item)) {
      url = `/${item.productId}_${lang}`;
    } else if (path) {
      url = `/wizard/${path}/${itemObject?.id}`;
    } else {
      url = `/${itemObject?.id}`;
    }

    return url;
  };

  return (
    <ProductListWrapper
      to={buildHref()}
      $noHref={isWizard(item) && !item?.children}
      inSearchSidebar={inSearchSidebar}
    >
      <ProductListItem>
        <ProductImage
          loading="lazy"
          src={`${consts.imagesCdnUrl}/images/${itemObject?.imageId}`}
          alt={itemObject?.title}
        />
        <Product $noHref={isWizard(item) && !item?.children}>
          <ProductContent>
            <ProductTitle>
              {itemObject?.title ? itemObject?.title : 'Missing title'}
            </ProductTitle>
            <ChevronIcon
              loading="lazy"
              src="/images/chevron-right.svg"
              alt="chevron"
            />
          </ProductContent>
        </Product>
      </ProductListItem>
    </ProductListWrapper>
  );
};

export default ProductItem;
