import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

SwiperCore.use([Navigation, Pagination]);

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  width: 100%;

  .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    pointer-events: none;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.elvitaMidGray};
    opacity: 1;
    margin: 0 1.5px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  }
`;

const StyledSwiper = styled(Swiper)<{ $isStartPage?: boolean }>`
  width: 100%;
  min-width: ${({ $isStartPage }) => $isStartPage && '400px'};
  margin-top: ${({ $isStartPage }) => $isStartPage && '20px'};
  height: ${({ $isStartPage }) => ($isStartPage ? '330px' : '260px')};
`;

const NavigationItem = styled.div<{ hide?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  z-index: 8;
  cursor: pointer;
  left: 0;
  margin-left: 2%;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};

  &:nth-child(2) {
    transform: rotate(180deg);
    left: unset;
    right: 2%;
  }

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    margin-left: 16%;

    &:nth-child(2) {
      right: 16%;
    }
  }
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.1);
`;

type CarouselProps = {
  onIndexChange?(index: number): void;
  itemsLength: number;
  className?: string;
  isStartPage?: boolean;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  onIndexChange,
  itemsLength,
  className,
  isStartPage,
}) => {
  const [index, setIndex] = React.useState(0);
  const [showCaret, setShowCaret] = React.useState(false);
  const swiperRef = React.useRef<SwiperCore | null>(null);
  const location = useLocation();

  const handleIndexChange = (index: number) => {
    setIndex(index);
    onIndexChange && onIndexChange(index);
  };

  React.useEffect(() => {
    setIndex(0);
    swiperRef.current?.slideTo(0);
  }, [location]);

  const params = {
    slidesPerView: 1.2,
    centeredSlides: true,
    loop: false,
    pagination: true,
    spaceBetween: 15,
  };
  return (
    <OuterWrapper
      onMouseEnter={() => setShowCaret(true)}
      onMouseLeave={() => setShowCaret(false)}
    >
      <Wrapper>
        <NavigationItem
          className={`prev-${className}`}
          hide={!index || !showCaret || isMobile}
        >
          <Circle style={{ paddingRight: 3 }}>
            <img src="/images/caret.svg" alt="" />
          </Circle>
        </NavigationItem>
        <NavigationItem
          className={`next-${className}`}
          hide={index === itemsLength - 1 || !showCaret || isMobile}
        >
          <Circle style={{ paddingRight: 3 }}>
            <img src="/images/caret.svg" alt="" />
          </Circle>
        </NavigationItem>
        <StyledSwiper
          $isStartPage={isStartPage}
          {...params}
          onSlideChange={({ realIndex }) => handleIndexChange(realIndex)}
          navigation={{
            prevEl: `.prev-${className}`,
            nextEl: `.next-${className}`,
          }}
          className={className}
          onSwiper={swiper => (swiperRef.current = swiper)}
        >
          {children}
        </StyledSwiper>
      </Wrapper>
    </OuterWrapper>
  );
};

export default Carousel;
