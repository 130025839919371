import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useLanguageStore } from 'src/context/LanguageStore';
import { useParams } from 'react-router-dom';
import { isProduct, isWizard } from '../../helpers/wizard';
import consts from 'src/config/consts';

const ProductWrapper = styled.div`
  flex: 0 0 calc(50% - ${({ theme }) => theme.space.lg});
  height: 400px;
`;

const Product = styled(Link)<{ $noHref: boolean }>`
  display: flex;
  position: relative;
  height: 100%;
  cursor: ${({ $noHref }) => ($noHref ? 'default' : 'pointer')};
  pointer-events: ${({ $noHref }) => $noHref && 'none'};
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledH3 = styled.h3`
  position: absolute;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px;
  background-color: #fff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;

type ItemProps = {
  item: Elvita.WizardItem;
};

const Item: React.FC<ItemProps> = ({ item }) => {
  const lang = useLanguageStore(state => state.lang);
  const { path } = useParams<{ path: string }>();
  const [itemObject, setItemObject] = React.useState<
    { id: string; title?: string; imageId: string } | undefined
  >(undefined);

  React.useEffect(() => {
    if (isWizard(item)) {
      setItemObject({
        id: item.id,
        imageId: item.imageId,
        title: item.title,
      });
    }
  }, [item]);

  const buildHref = (): string => {
    let url: string;
    if (isProduct(item)) {
      url = `/${item.productId}_${lang}`;
    } else if (path) {
      url = `/wizard/${path}/${itemObject?.id}`;
    } else {
      url = `/${itemObject?.id}`;
    }

    return url;
  };

  return (
    <ProductWrapper>
      <Product to={buildHref()} $noHref={isWizard(item) && !item?.children}>
        <Image src={`${consts.imagesCdnUrl}/images/${itemObject?.imageId}`} />
        <StyledH3>
          {itemObject?.title ? itemObject?.title : 'Missing title'}
        </StyledH3>
      </Product>
    </ProductWrapper>
  );
};

export default Item;
