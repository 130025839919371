import axios from 'axios';
import { format } from 'date-fns';
import * as React from 'react';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { getDomainLanguage, useLanguageStore } from 'src/context/LanguageStore';
import {
  useFetchStartPage,
  useProductConfig,
  useProductConfigs,
} from 'src/hooks/useFetchData';
import { useTranslation } from 'src/hooks/useTranslation';
import styled from 'styled-components';
import { MainContent } from './layout/MainContent';
import PageContentWrapper from './layout/PageContentWrapper';
import { Link } from 'react-router-dom';
import consts from 'src/config/consts';

const StyledMainContent = styled(MainContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LowerRow = styled.div`
  width: 55%;
  padding: 20px;
  justify-content: space-between;
  display: flex;
  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    width: 100%;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const RightContent = styled.div`
  /* padding-bottom: 10px; */
`;

const ExternalLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: 12px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;

const CopyrightText = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.elvitaGray};
`;

const Button = styled.button`
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  border-radius: 6px;
  padding: 6px 8px 5px;
`;

const A = styled.a`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;

const CaretIcon = styled.img`
  width: 6px;
  height: 12px;
  transform: rotate(180deg);
  margin-left: ${({ theme }) => theme.space.xs};
`;

const languageCollection = [
  { code: 'en-GB', name: 'English' },
  { code: 'sv-SE', name: 'Svenska' },
  { code: 'nb-NO', name: 'Norsk' },
  { code: 'da-DK', name: 'Dansk' },
  { code: 'fi-FI', name: 'Suomi' },
  { code: 'is-IS', name: 'íslenska' },
  { code: 'de-DE', name: 'Deutsch' },
];

const countryCollection = [{ code: 'sv-SE', name: 'Sverige' }];

const useLanguages = () => {
  const { data: productConfigs, isLoading: manyLoading } = useProductConfigs();
  const { data: productConfig, isLoading: singleLoading } = useProductConfig();
  const ktyValidity = productConfig?.KtyValidity;

  let languageList = [] as {
    productId: string;
    langName: string;
    langCode: string;
    manifestId: string;
  }[];

  if (ktyValidity > 0 && productConfigs && productConfig) {
    // Filter productconfigs on KtyValidity
    const filteredProductConfigs = productConfigs?.filter(
      productConfig => productConfig.KtyValidity === ktyValidity,
    );

    languageList = filteredProductConfigs?.map(productConfig => {
      const langObj = languageCollection.find(
        language => language.code === productConfig.Lang,
      );
      if (!langObj) return undefined;
      return {
        productId: productConfig.ProductId,
        langName: langObj.name,
        langCode: langObj.code,
        manifestId: productConfig.ManifestId,
      };
    });
  }
  //filter out undefined from languagelist
  const filteredLanguageList = languageList.filter(
    language => language !== undefined,
  );
  const startPageQueries = useQueries(
    filteredLanguageList?.map(lang => {
      return {
        queryKey: ['langDropdown', lang.manifestId],
        queryFn: () =>
          axios({
            method: 'get',
            url: `/manifests/${lang.manifestId}/startpage.json`,
            baseURL: consts.storageUrl,
          }).then(response => response.data),
        enabled: !!lang.manifestId && filteredLanguageList?.length > 0,
        retry: false,
        retryOnMount: false,
        select: () => {
          return {
            langName: lang.langName,
            langCode: lang.langCode,
            productId: lang.productId,
          };
        },
      };
    }),
  );

  const isLoading =
    startPageQueries.some(result => result.isLoading) ||
    manyLoading ||
    singleLoading;

  if (isLoading) return null;

  const availableProducts = startPageQueries
    .map(result => {
      if (result.isError) return null;
      return {
        languageLabel: result.data.langName,
        link: `/${result.data.productId}`,
        languageCode: result.data.langCode,
      };
    })
    .filter(product => product !== null);

  if (availableProducts.length === 0 && productConfig === undefined) {
    // return all languages
    return languageCollection.map(language => {
      return {
        languageLabel: language.name,
        link: '',
        languageCode: language.code,
      };
    });
  }

  return availableProducts;
};

const LinkRow = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 14px 0;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin: 0;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: 55%;
  }
`;

const PublishedDate = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 10px;
  color: #bdbdbd;
  margin: 0 0 5px 0;
`;

const CurrentLanguage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CountryParagraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: ${({ theme }) => theme.lineHeights.xxxxs};
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.elvitaBlack};
`;

const LanguageButton = styled.button<{ $active: boolean }>`
  display: flex;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  border-radius: 4px;
  padding: 3px 10px;
  gap: 4px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.elvitaWhite : theme.colors.elvitaWebPurple};
`;

const LanguageButtonText = styled.p<{ $active: boolean }>`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: ${({ theme }) => theme.lineHeights.xxxs};
  padding-top: 1px;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.elvitaWebPurple : theme.colors.elvitaWhite};
`;
const LinkText = styled.a`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: ${({ theme }) => theme.lineHeights.xxxs};
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.elvitaWhite};
`;

const StyledLanguageList = styled.ul`
  padding: 16px;
  width: 157px;
  border-radius: 7px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  background-color: ${({ theme }) => theme.colors.elvitaWhite};
`;

const LanguageListItem = styled.li`
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

const LanguageLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;

const LanguageText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.xsm};
  color: ${({ theme }) => theme.colors.elvitaPurpleDark};
`;

const EmailGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EmailText = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  padding-top: 1px;
  line-height: ${({ theme }) => theme.lineHeights.xxxs};
  color: ${({ theme }) => theme.colors.elvitaWhite};
  text-decoration: none;
`;

const PdfLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SelectedLanguageBall = styled.div<{ $active: boolean }>`
  background-color: transparent;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme, $active }) =>
    $active && theme.colors.elvitaWebPurple};
`;

const BackgroundWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  width: 100%;
`;

const LanguageList: React.FC = () => {
  const lang = useLanguageStore(state => state.lang);
  const setLang = useLanguageStore(state => state.setLang);
  const { productId } = useParams<{ productId: string }>();
  const languages = useLanguages();

  if (!languages && productId) return null;

  return (
    <StyledLanguageList>
      {languages?.map(language => (
        <LanguageListItem
          key={language.languageLabel}
          onClick={
            language.link === '' ? () => setLang(language.languageCode) : null
          }
        >
          <LanguageLink to={language.link}>
            <SelectedLanguageBall $active={lang === language.languageCode} />
            <LanguageText>{language.languageLabel}</LanguageText>
          </LanguageLink>
        </LanguageListItem>
      ))}
    </StyledLanguageList>
  );
};

export function useFetchFooter() {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery(
    ['pdf-footer', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/footer.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => response.data)
        .catch(error => error.response),
    { enabled: !!manifestId },
  );
}

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const lang = useLanguageStore(state => state.lang);
  const { data } = useFetchStartPage();
  const { data: footerData } = useFetchFooter();
  const lastModified = data
    ? format(new Date(data.lastModified), 'yyyyMMdd')
    : null;

  const currentYear = new Date().getFullYear();

  const copyrightText = (
    t('footer.copyrightText') ?? `© _YEAR_ Elvita`
  ).replace(/_YEAR_/g, currentYear.toString());

  const languageName = languageCollection.find(
    language => language.code === lang,
  )?.name;

  let country = 'Sverige';
  const domainLang = getDomainLanguage();
  if (domainLang) {
    country = countryCollection.find(
      language => language.code === domainLang,
    ).name;
  }

  const [languageSelectorOpen, setLanguageSelectorOpen] = React.useState(false);

  return (
    <PageContentWrapper type="footer">
      <StyledMainContent>
        <BackgroundWrapper>
          <LinkRow>
            <Links>
              <Popover
                padding={10}
                boundaryInset={10}
                isOpen={languageSelectorOpen}
                positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                content={<LanguageList />}
                onClickOutside={() => setLanguageSelectorOpen(false)}
              >
                <LanguageButton
                  onClick={() => setLanguageSelectorOpen(true)}
                  $active={languageSelectorOpen}
                >
                  <img
                    src={
                      languageSelectorOpen
                        ? `/images/globe.svg`
                        : `/images/white-globe.svg`
                    }
                  />
                  <LanguageButtonText $active={languageSelectorOpen}>
                    {languageName}
                  </LanguageButtonText>
                </LanguageButton>
              </Popover>
              {footerData &&
                Array.isArray(footerData) &&
                footerData.map(item => (
                  <PdfLink
                    key={item.href}
                    href={`${consts.storageUrl}${consts.filePath}pdfs/head/${item.href}`}
                  >
                    <img src="/images/white-file-arrow-down.svg" />
                    <LinkText>{'PDF manual'}</LinkText>
                  </PdfLink>
                ))}
              <EmailGroup>
                <img src="/images/white-envelope.svg" />
                <EmailText href={`mailto:${t('footer.infoEmailLink')}`}>
                  {t('footer.infoEmailLink')}
                </EmailText>
              </EmailGroup>
            </Links>
          </LinkRow>
        </BackgroundWrapper>

        <Row>
          <LowerRow>
            <LeftContent>
              <CurrentLanguage>
                <img src="/images/location-dot.svg" />
                <CountryParagraph>{country}</CountryParagraph>
              </CurrentLanguage>

              <ExternalLink
                target="_blank"
                href={t('footer.privacyPolicyLink')}
              >
                {t('footer.privacyPolicy')}
              </ExternalLink>
              <CopyrightText>{copyrightText}</CopyrightText>
            </LeftContent>
            <RightContent>
              <PublishedDate>
                {data ? `Publ. ${lastModified}` : ''}
              </PublishedDate>
              <Button>
                <A target="_blank" href={t('footer.buttonLink')}>
                  {t('footer.buttonText')}
                  <CaretIcon src="/images/caret.svg" />
                </A>
              </Button>
            </RightContent>
          </LowerRow>
        </Row>
      </StyledMainContent>
    </PageContentWrapper>
  );
};

export default Footer;
