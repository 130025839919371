export default {
  videoPath: 'lodgestore/elvita/oi/videos/head',
  filePath: 'lodgestore/elvita/oi/',
  storageUrl: process.env.REACT_APP_STORAGE_URL,
  gaKey: process.env.REACT_APP_GA_KEY,
  searchUrl: process.env.REACT_APP_SEARCH_URL,
  implementation: process.env.REACT_APP_IMPLEMENTATION,
  contentVersion: process.env.REACT_APP_CONTENT_VERSION,
  imagesCdnUrl: process.env.REACT_APP_IMAGES_CDN_URL,
};
