import * as React from 'react';
import { MainContent } from '../components/layout/MainContent';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import SearchBox from '../components/search/SearchBox';
import H2 from '../components/typography/H2';
import { useTranslation } from '../hooks/useTranslation';
import styled from 'styled-components';
import SearchResultsTopic from 'src/components/search/SearchResultsTopic';
import ProductList from '../components/ProductList';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useProductConfig } from 'src/hooks/useFetchData';

const SearchbarWrapper = styled.div<{ hasResults: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.elvitaWebBeige};
  padding: ${({ theme }) => theme.space.sm} 0;
  margin: ${({ theme, hasResults }) =>
    hasResults
      ? `0 -${theme.space.s} ${theme.space.s} -${theme.space.s}`
      : `0 -${theme.space.s} ${theme.space.xl} -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: ${({ theme, hasResults }) =>
      hasResults ? `0 0 ${theme.space.s} 0` : `0 0 ${theme.space.xl} 0`};
  }
`;

const StyledH2 = styled(H2)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
  margin: 0 auto ${({ theme }) => theme.space.xl};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 50%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 50%;
  }
`;

const EmptyResults = styled.div`
  margin: 0 auto;
  span {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 50%;
  }
`;

const EmptyProductResults = styled.div`
  text-align: center;
  a {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.l};
`;

const Search: React.FC = () => {
  const { t } = useTranslation();
  const { trackSiteSearch, trackPageView } = useMatomo();
  const { productId, searchType, term } = useParams<{
    productId?: string;
    searchType: Elvita.SearchType;
    term: string;
  }>();
  const [loading, setLoading] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [searchResults, setSearchResults] = React.useState<
    Elvita.SearchResultDocument[] | Elvita.ProductSearchDocument[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string>(term || '');
  const { data: productConfig } = useProductConfig();

  React.useEffect(() => {
    if (
      productId &&
      (!productConfig || productConfig.ProductId !== productId)
    ) {
      return;
    }

    if (term) {
      setSearchTerm(term);
      setLoading(true);
    } else {
      setLoading(false);
    }
    setInitialLoad(false);
  }, [productId, productConfig, term]);

  const handleSearchResults = React.useCallback(
    (
      data: Elvita.SearchResultDocument[],
      searchTerm: string,
      loading: boolean,
    ) => {
      setLoading(loading);
      setSearchResults(data);
      setSearchTerm(searchTerm);

      if (searchTerm && searchTerm !== '' && !loading) {
        trackSiteSearch({
          keyword: searchTerm,
          category: searchType,
        });
      }
    },
    [searchType, trackSiteSearch],
  );

  useMetaTitle();

  React.useEffect(() => {
    trackPageView(null);
  }, []);

  return (
    <Page
      isWizard={searchType === 'product'}
      hideMenu={searchType === 'product'}
    >
      <PageContentWrapper flex={1}>
        {searchType === 'product' && (
          <Breadcrumbs
            inWizard={true}
            hideLast={false}
            breadcrumbs={[
              { label: t('product.search.title') ?? 'Search', url: '#' },
            ]}
          />
        )}
        <SearchbarWrapper
          hasResults={searchResults && searchResults.length > 0}
        >
          <SearchBox
            setSearchResults={handleSearchResults}
            isSearchPage={true}
            searchType={searchType}
          />
        </SearchbarWrapper>
        <MainContent>
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : searchTerm ? (
            searchResults.length > 0 ? (
              searchType === 'product' ? (
                <ProductList
                  items={
                    searchResults as
                      | Elvita.WizardItem[]
                      | Elvita.ProductSearchDocument[]
                  }
                />
              ) : (
                <SearchResultsTopic
                  items={searchResults as Elvita.SearchResultDocument[]}
                />
              )
            ) : searchType === 'product' ? (
              <EmptyProductResults>
                {t('product.search.noResults.text')}{' '}
                <a href={t('product.search.noResults.link') ?? '#'}>
                  {t('product.search.noResults.linkLabel')}
                </a>
              </EmptyProductResults>
            ) : (
              <EmptyResults>
                {t('search.noResults')} <span>{searchTerm}</span>
              </EmptyResults>
            )
          ) : !initialLoad ? (
            <>
              <StyledH2>{t(`${searchType}.search.title`)}</StyledH2>
              <Text>{t(`${searchType}.search.preamble`)}</Text>
              <StyledImage
                src={`/images/${
                  searchType === 'product'
                    ? 'search-product.png'
                    : 'search-content.jpg'
                }`}
              />
            </>
          ) : null}
        </MainContent>
      </PageContentWrapper>
    </Page>
  );
};

export default Search;
