import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'src/hooks/useTranslation';
import styled from 'styled-components';
import Button from './Button';
import FeedbackModal from './modal/FeedbackModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.space.xl} 0;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 19.2px;
  color: ${({ theme }) => theme.colors.elvitaGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space.sm};
`;

const ThankYou = styled(Text)``;

const Space = styled.div`
  width: 20px;
`;

const Feedback: React.FC = () => {
  const location = useLocation();

  //Reset feedback state when moving directly between topics
  React.useEffect(() => {
    setDisplayResponse(false);
  }, [location]);

  const { t } = useTranslation();
  const [displayResponse, setDisplayResponse] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const trackFeedback = (feedbackType: string) => {
    window._paq = window._paq || [];
    window._paq.push(['trackEvent', 'Feedback', feedbackType]);
  };

  const clickHandler = React.useCallback(
    (show: boolean, feedbackProvided?: boolean): void => {
      setShowModal(show);
      if (feedbackProvided) {
        setDisplayResponse(true);
        trackFeedback(feedbackProvided ? 'positive' : 'negative');
      }
    },
    [],
  );

  return (
    <Wrapper>
      {displayResponse ? (
        <ThankYou>{t('feedback.thankYou') ?? 'feedback.thankYou'}</ThankYou>
      ) : (
        <>
          <Text>{t('feedback.text') ?? 'Was this information helpful?'}</Text>
          <ButtonWrapper>
            <Button
              text={t('feedback.buttonYes') ?? 'Yes'}
              onClick={() => {
                setDisplayResponse(true);
                trackFeedback('Positive');
              }}
              secondary={true}
              className="feedback-positive"
            />
            <Space />
            <Button
              text={t('feedback.buttonNo') ?? 'No'}
              onClick={() => clickHandler(true)}
              secondary={true}
            />
          </ButtonWrapper>
        </>
      )}
      <FeedbackModal
        isVisible={showModal}
        closeHandler={feedbackProvided => clickHandler(false, feedbackProvided)}
      />
    </Wrapper>
  );
};

export default Feedback;
