import { useLanguageStore } from 'src/context/LanguageStore';
import { useQuery } from 'react-query';
import { useProductConfig } from './useFetchData';
import consts from 'src/config/consts';
import axios from 'axios';

const searchApi = axios.create({
  baseURL: consts.searchUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const queryContent = async ({
  manifestId,
  lang,
  term,
  forceGlobalSearch = false,
}: {
  manifestId: string;
  lang: string;
  term: string;
  forceGlobalSearch?: boolean;
}) => {
  const filter = forceGlobalSearch
    ? `languageCode eq '${lang}'`
    : `manifestId eq '${manifestId}' and languageCode eq '${lang}'`;

  const res = await searchApi.post(
    `/${consts.implementation}/${consts.contentVersion}/content`,
    {
      search: `${term}*`,
      filter: filter,
    },
  );
  return res.data;
};

const queryProducts = async ({
  lang,
  term,
}: {
  lang: string;
  term: string;
}) => {
  const filter = `languageCode eq '${lang}'`;
  const res = await searchApi.post(
    `/${consts.implementation}/${consts.contentVersion}/product`,
    {
      search: `/.*${term}.*/`,
      filter: filter,
      queryType: 'full',
    },
  );
  return res.data;
};

export default function useSearch(
  term: string,
  type: Elvita.SearchType,
  forceGlobalSearch = false,
) {
  const lang = useLanguageStore(state => state.lang);
  const { data: productConfig } = useProductConfig();

  const shouldUseProductContext = !forceGlobalSearch && type === 'content';

  return useQuery(
    [
      type === 'product' ? 'productSearch' : 'search',
      term,
      lang,
      shouldUseProductContext ? productConfig?.ManifestId : null,
    ],
    type === 'product'
      ? () => queryProducts({ lang, term })
      : () =>
          queryContent({
            lang,
            term,
            manifestId: shouldUseProductContext
              ? (productConfig?.ManifestId as string)
              : '',
            forceGlobalSearch: !shouldUseProductContext,
          }),
    {
      enabled:
        term.length > 2 &&
        (forceGlobalSearch || !shouldUseProductContext || !!productConfig) &&
        !!lang,
      select(data) {
        return data.value;
      },
    },
  );
}
