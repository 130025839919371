import * as React from 'react';
import Page from '../components/layout/Page';
import H1 from '../components/typography/H1';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { MainContent } from '../components/layout/MainContent';
import styled from 'styled-components';
import SearchBox from '../components/search/SearchBox';
import { useTranslation } from '../hooks/useTranslation';
import { useFetchWizardStructure } from '../hooks/useFetchData';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { Link } from 'react-router-dom';
import consts from 'src/config/consts';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const SearchbarWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) =>
    `${theme.space.xl} ${theme.space.sm} ${theme.space.xs} ${theme.space.sm}`};
  margin: ${({ theme }) => `0 -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: 0;
  }
`;

const StyledH1 = styled(H1)`
  letter-spacing: 0.06em;
  line-height: 29px;
  font-size: 32px;
  margin-bottom: ${({ theme }) => theme.space.xs};
  margin-left: ${({ theme }) => theme.space.md};

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    margin: 0;
    font-size: 24px;
  }
`;

const CategoryItems = styled.div`
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.sm};
  justify-content: flex-start;
  margin-left: 40px;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    flex-direction: column;
    margin: 0;
  }
`;

const CategoryItem = styled(Link)`
  display: flex;
  position: relative;
  flex: 0 0 calc(50% - ${({ theme }) => theme.space.lg});
  height: 400px;
`;

const CategoryImage = styled.img`
  height: 100%;
  object-fit: cover;
`;

const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px;
  background-color: #fff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;

const TitleWithoutImage = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
`;
const A = styled(Link)`
  margin-top: ${({ theme }) => theme.space.m};
  align-self: flex-end;
`;

const Startpage = () => {
  const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  const { error, data } = useFetchWizardStructure();
  useMetaTitle();

  React.useEffect(() => {
    trackPageView(null);
  }, []);

  if (error || !data) {
    return null;
  }

  return (
    <Page hideMenu={true} hideSearch={true} isWizard={true}>
      <PageContentWrapper flex={1}>
        <SearchbarWrapper>
          <StyledH1>{t('startpage.title')}</StyledH1>
        </SearchbarWrapper>
        <MainContent>
          <CategoryItems>
            {data?.data.map((item, index) => <Item key={index} item={item} />)}
          </CategoryItems>
        </MainContent>
      </PageContentWrapper>
    </Page>
  );
};

type ItemProps = {
  item: Elvita.WizardItem;
};

const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <CategoryItem to={`wizard/${item.id}`}>
      {item.imageId ? (
        <>
          <CategoryImage
            src={`${consts.imagesCdnUrl}/images/${item.imageId}`}
          />
          <Title>{item.title}</Title>
        </>
      ) : (
        <TitleWithoutImage>{item.title}</TitleWithoutImage>
      )}
    </CategoryItem>
  );
};

export default Startpage;
