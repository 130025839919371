import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import { PageContentRow } from '../components/layout/PageContentRow';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { useFetchBrowse } from '../hooks/useFetchData';
import { useTranslation } from 'src/hooks/useTranslation';
import { MainContent } from '../components/layout/MainContent';
import BrowseContent from '../components/BrowseContent';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useNodes } from 'src/hooks/useNodes';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Browse: React.FC = () => {
  const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  const { childId, path } = useParams<{ childId: string; path: string }>();
  const { isLoading, error, data } = useFetchBrowse();
  const [topLevelItem, setTopLevelItem] = React.useState<
    Elvita.Node | undefined
  >(undefined);
  useMetaTitle();

  React.useEffect(() => {
    if (data && data.status === 200) {
      const product = data.data[0];

      setTopLevelItem(
        path
          ? product?.children.find(item => item.topicId === path)
          : data.data[0],
      );
    } else {
    }
  }, [data, path]);

  React.useEffect(() => {
    trackPageView(null);
  }, []);

  const { breadcrumbs } = useNodes(
    topLevelItem,
    childId,
    path,
    topLevelItem?.title ?? '',
  );

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <PageContentRow center={true}>
          <MainContent>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {!error && data?.status === 200 ? (
              <BrowseContent
                parentNode={topLevelItem}
                childId={childId}
                path={path}
                loading={isLoading}
              />
            ) : (
              <div>{t('browse.errormessage')}</div>
            )}
          </MainContent>
        </PageContentRow>
      </PageContentWrapper>
    </Page>
  );
};

export default Browse;
