import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks/useTranslation';
import { useInternalLink } from '../hooks/useInternalLink';
import { Link } from 'react-router-dom';

const Crumbs = styled.ul<{ inWizard?: boolean; inSearchResults?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${({ theme, inWizard, inSearchResults }) =>
    inSearchResults || inWizard
      ? `${theme.space.xxs} 0`
      : `${theme.space.sm} 0 ${theme.space.m} 0`};
  margin: ${({ theme, inWizard }) => inWizard && `20px ${theme.space.xs} 0`};
  padding-top: ${({ inSearchResults }) => inSearchResults && 0};

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin: ${({ theme, inWizard }) => inWizard && `20px 0 0 0`};
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    padding-top: ${({ theme, inWizard, inSearchResults }) =>
      !inWizard && !inSearchResults && theme.space.m};
    margin: ${({ theme, inWizard }) => inWizard && `20px 0 0 0`};
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    padding-top: ${({ theme, inWizard, inSearchResults }) =>
      !inWizard && !inSearchResults && theme.space.md};
    margin: ${({ theme, inWizard }) =>
      inWizard && `${theme.space.md} ${theme.space.m} 0`};
  }
`;

const Crumb = styled.li`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 11px;

  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
  text-transform: uppercase;
  padding: ${({ theme }) => `0 ${theme.space.xxs}`};

  &:nth-child(2n) {
    padding: 0;
  }
`;

const A = styled(Link)<{ $current: boolean; $hideLast: boolean }>`
  cursor: ${({ $current, $hideLast }) =>
    $current && !$hideLast ? 'initial' : 'pointer'};
  color: ${({ $current, $hideLast, theme }) =>
    $current && !$hideLast && theme.colors.elvitaGray};
  pointer-events: ${({ $current, $hideLast }) =>
    $current && !$hideLast && 'none'};
  &:hover {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }
`;

const SearchResultCrumb = styled.li`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 9px;
  line-height: 10.8px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaPurpleDark};

  &:first-child {
    padding-left: ${({ theme }) => theme.space.xxs};
  }
`;

type BreadcrumbsProps = {
  breadcrumbs?: Elvita.BreadCrumb[];
  inWizard?: boolean;
  hideLast?: boolean;
  inSearchResult?: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs = [],
  inWizard,
  hideLast,
  inSearchResult,
}) => {
  const { t } = useTranslation();
  const { internalLink } = useInternalLink();
  const listRef = React.useRef<HTMLUListElement | null>(null);
  const crumbs: Elvita.BreadCrumb[] = !inSearchResult
    ? [{ label: t('breadcrumbs.home') ?? 'Start', url: '/' }, ...breadcrumbs]
    : breadcrumbs;

  if (inSearchResult) {
    if (
      breadcrumbs.length < 1 ||
      !breadcrumbs.every(el => el.label.length > 0)
    ) {
      return null;
    }

    return (
      <Crumbs ref={listRef} inWizard={inWizard} inSearchResults={true}>
        {crumbs.map((crumb, index) => (
          <React.Fragment key={index}>
            <SearchResultCrumb>{crumb.label}</SearchResultCrumb>
            {breadcrumbs.length !== index + 1 && (
              <SearchResultCrumb>/</SearchResultCrumb>
            )}
          </React.Fragment>
        ))}
      </Crumbs>
    );
  }

  return (
    <Crumbs ref={listRef} inWizard={inWizard}>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          <Crumb>
            <A
              to={internalLink(crumb.url)}
              $current={crumbs.length - 1 === index && !inWizard}
              $hideLast={hideLast}
            >
              {crumb.label}
            </A>
          </Crumb>
          {(breadcrumbs.length !== index || hideLast) && <Crumb>/</Crumb>}
        </React.Fragment>
      ))}
    </Crumbs>
  );
};
export default Breadcrumbs;
