import * as React from 'react';
import styled from 'styled-components';
import { isProduct } from '../helpers/wizard';
import Item from './productnav/Item';
import ProductItem from './productnav/ProductItem';
import H2 from './typography/H2';
import { useTranslation } from 'src/hooks/useTranslation';
import theme from 'src/config/theme';

type ListStyle = 'column' | 'row';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductsWrapper = styled.div<{
  styleType: ListStyle;
  inSearchSidebar?: boolean;
}>`
  display: flex;
  flex-direction: ${({ styleType }) => styleType};
  flex-wrap: ${({ styleType }) => (styleType === 'column' ? 'nowrap' : 'wrap')};
  gap: ${({ theme }) => theme.space.sm};
  justify-content: flex-start;
  margin: ${({ inSearchSidebar }) =>
    inSearchSidebar ? `0 ${theme.space.xs}` : `0 0 0 40px`};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin: 0 ${({ theme }) => theme.space.xs};
  }

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    flex-direction: column;
    margin: 0 ${({ theme }) => theme.space.xs};
  }
`;

const ProductCategoryTitle = styled(H2)<{ inSearchSidebar?: boolean }>`
  margin: ${({ inSearchSidebar, theme }) =>
    inSearchSidebar
      ? `${theme.space.m} ${theme.space.xs} ${theme.space.s}`
      : '40px 0 25px 20px'};

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    margin: ${({ inSearchSidebar, theme }) =>
      inSearchSidebar ? `${theme.space.s} ${theme.space.xs}` : '20px 0 15px 0'};
  }
`;

type ProductListProps = {
  items: Elvita.ProductSearchDocument[] | Elvita.WizardItem[];
  title?: string;
  inSearchSidebar?: boolean;
};

const ProductList: React.FC<ProductListProps> = ({
  items,
  title,
  inSearchSidebar,
}) => {
  const [style, setStyle] = React.useState<ListStyle>('column');
  const { t } = useTranslation();

  React.useEffect(() => {
    const isProductList = items.some(
      item => 'objectId' in item || isProduct(item),
    );

    if (window.innerWidth > 640 && !isProductList) {
      setStyle('row');
    } else {
      setStyle('column');
    }
  }, [items]);

  if (!items || items.length < 1) {
    return null;
  }

  const groupedItems: Record<
    string,
    Array<Elvita.ProductSearchDocument | Elvita.WizardItem | Elvita.Product>
  > = {};

  items.forEach(item => {
    if (isProduct(item) && item.category) {
      const translatedCategory = t(`wizard.${item.category}`);
      if (!groupedItems[translatedCategory]) {
        groupedItems[translatedCategory] = [];
      }
      groupedItems[translatedCategory].push(item);
    } else {
      if (!groupedItems['']) {
        groupedItems[''] = [];
      }
      groupedItems[''].push(item);
    }
  });

  return (
    <Wrapper>
      {Object.entries(groupedItems).map(([category, categoryItems]) => (
        <div key={category}>
          {category && (
            <ProductCategoryTitle inSearchSidebar={inSearchSidebar}>
              {category}
            </ProductCategoryTitle>
          )}
          <ProductsWrapper styleType={style} inSearchSidebar={inSearchSidebar}>
            {categoryItems.map((item, index) => {
              if ('objectId' in item) {
                const productId = item.objectId.split('_')[0];
                return (
                  <ProductItem
                    key={index}
                    item={{
                      ...item,
                      validity: Number(productId),
                      productId: productId,
                      title: productId,
                    }}
                    inSearchSidebar={inSearchSidebar}
                  />
                );
              }
              return isProduct(item) ? (
                <ProductItem
                  key={index}
                  item={{
                    ...item,
                    title: item.productId,
                  }}
                  inSearchSidebar={inSearchSidebar}
                />
              ) : (
                <Item key={index} item={item} />
              );
            })}
          </ProductsWrapper>
        </div>
      ))}
    </Wrapper>
  );
};

export default ProductList;
