import { useFetchWizardStructure } from './useFetchData';

export function useProductCategory() {
  const { data: wizardData } = useFetchWizardStructure();

  const findCategory = (manifestId: string): string | undefined => {
    if (!wizardData?.data || !manifestId) return undefined;

    const validity = parseInt(manifestId.split('_')[0], 10);
    const stack = [...wizardData.data];

    while (stack.length) {
      const item = stack.pop();
      if (!item) continue;

      if (item.validity === validity) {
        return item.category;
      }

      if (item.children) {
        stack.push(...item.children);
      }
    }

    return undefined;
  };

  return { findCategory };
}
